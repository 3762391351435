import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import AboveFooterSection from '../../components/AboveFooterSection'

import ModalImage from 'react-modal-image'
import { StaticImage } from 'gatsby-plugin-image'
import streaming_data_platform from '../../resources/images/clients/viseca/streaming_data_platform.png'
import streaming_platform_adoption from '../../resources/images/clients/viseca/streaming_platform_adoption.png'

const viseca = () => {
  const data = useStaticQuery(graphql`
    query VisecaSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "VisecaCaseStudy" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />

      <section>
        <div className="container-1 hero-margin">
          <div className="d-flex flex-wrap justify-content-center mobile-padding-top-half">
            <div className="col-lg-9 col-md-12 col-12 blog-content-parent ">
              <div className="text-center">
                <StaticImage
                  className="w-20 mobile-w-100"
                  src="../../resources/images/case_studies/viseca/viseca-logo.png"
                  quality={100}
                  alt="Viseca"
                  placeholder="tracedSVG"
                />
              </div>
              <div className="w-75 mx-auto mt-4 mb-3">
                <h1 className="text-center my-2 lh-2-3">
                  Delivering customer apps 10x faster using Apache Kafka
                </h1>
              </div>
              <p className="f-20 text-center px-5 my-4 mobile-padding-reset mobile-margin-top">
                How did a highly regulated Swiss payment provider move an
                event-driven marketing platform into production in under a year?
              </p>
              <div className="d-flex justify-content-center mb-3 mt-4 mobile-margin-top">
                <StaticImage
                  src="../../resources/images/case_studies/viseca/viseca-hero-new.png"
                  quality={100}
                  placeholder="tracedSVG"
                  alt="Viseca hero"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <section className="bg-cyan mobile-margin-top mt-5">
          <div className="container-1 py-5">
            <div className="d-flex flex-wrap justify-content-around">
              <div className="col-sm col-12 px-4 text-center">
                <h2 className="f-32 fw-900">
                  From zero <br /> to production
                </h2>
                <p>
                  In <b> one year.</b>
                </p>
              </div>
              <div className="col-sm col-12 px-4 text-center mobile-margin-top">
                <h2 className="f-32 fw-900">
                  10x reduced <br /> time-to-market
                </h2>
                <p>of streaming applications.</p>
              </div>
              <div className="col-sm col-12 px-4 text-center mobile-margin-top">
                <h2 className="f-32 fw-900">
                  600k targeted <br /> communications
                </h2>
                <p>sent in strategic marketing campaigns via Kafka.</p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <p>
                Viseca is one of the leading Swiss cashless payment providers,
                with over two million customers. But the race is on for them to
                find new revenue streams.
              </p>
              <p>
                This is the story of how the Viseca team channelled the power of
                Apache Kafka across customer initiatives in just a year, using
                Lenses.io to deliver distributed data and streaming applications
                10x faster.
              </p>
              <h3 className="text-center mt-4 mb-2 mobile-margin-top">
                State of play
              </h3>
              <p>
                Financial institutions such as Viseca are built on foundations
                of trust. But with the arrival of new fintech challengers,
                there’s a need for traditional service providers to take that
                long-standing trust and turn it into instant, personalized
                customer service.
              </p>
              <p>
                Enter their greatest asset: data. When paired with the right
                technology, Viseca has the chance to go beyond what their
                customers expect by turning analytics insights into well-loved,
                well-timed experiences.
              </p>
              <p>
                From delivering mail voucher rewards on a customer’s birthday to
                instantly addressing service enquiries via mobile app, rewriting
                Viseca’s value in a real-time world requires a vision,
                infrastructure and delivery model for real-time data.
              </p>
              <h4 className="mobile-margin-top">Challenge</h4>

              <h3 className="text-center mt-4 mb-2">
                The demand for smarter data experiences
              </h3>

              <p>
                This was why Vladimiro Borsi (Vladi to his colleagues), IT
                Enterprise Architect at Viseca, began scoping a new Streaming
                Data Platform.
              </p>
              <p>
                Although this platform would be designed to support their
                marketing initiatives, it would also be the first step towards
                Viseca running their entire business in real-time.
              </p>

              <p>
                It was clear for Vladi that the solution design would need to go
                beyond integrating 15+ data systems that form part of their
                marketing applications - including CRM, direct mail, mobile app,
                email, website and others.
              </p>

              <p>
                It needed to support a continuous flow of events generated by
                applications across the entire business and meet Viseca’s strict
                corporate governance requirements.
              </p>

              <ModalImage
                className="mt-4 mb-3"
                small={streaming_data_platform}
                large={streaming_data_platform}
                alt="Streaming data platform"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <h4>PRINCIPLES</h4>
              <h3 className="text-center mt-4 mb-2">
                DataOps with 3 principles
              </h3>

              <p>
                Vladi and his team mapped out a scalable event-based
                architecture to support and sculpt a Streaming Data Platform
                based on the following principles:
              </p>
              <p className="text-dark text-uppercase h4-like mt-4">
                <b>1. Design data experiences for all</b>
              </p>

              <p>
                Supported by a team of governance specialists and technologists,
                Vladi worked with Lenses.io to provide monitoring, exploration
                and governance of real-time data. This enabled Viseca to
                simplify the use of Open-Source components such as Apache Kafka,
                and yet design a platform for business users.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-usecase-viseca py-5 mobile-margin-top mt-4">
        <div className="container-1 py-5">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-12">
              <p className="f-20 text-white text-center mb-0 ">
                “The Lenses.io team is experienced in supporting enterprise
                environments for financial institutions. Lenses is agnostic
                towards key Open-Source technologies, so we were confident the
                software would govern our new event data platform - which was
                confirmed with great results.”
              </p>

              <div className="mt-4 text-center">
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <StaticImage
                    src="../../resources/images/case_studies/viseca/vladi.png"
                    quality={100}
                    alt="Vladimiro Borsi"
                    placeholder="tracedSVG"
                  />
                  <p className="text-white fw-700 pl-3 mt-3 mobile-padding-reset">
                    Vladimiro Borsi, Enterprise IT Architect
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <p className="text-dark text-uppercase h4-like">
                <b>2. Meet data regulations</b>
              </p>

              <p>
                Fulfilling strict governance and compliance requirements is a
                baseline for this Swiss financial institution.
              </p>

              <div className="casestudy-container-right">
                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-20 quote-line-height">
                    <i>
                      “I believe the biggest value of Lenses.io is in governance
                      support, at operational process and information security
                      level, which makes it an ideal solution for enterprises
                      for whom scale is a matter of serious attention.”
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>Dario Carnelli, Governance Expert (Isaca Certified)</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-dark text-uppercase h4-like">
                <b>3. Move with customers</b>
              </p>

              <p>
                Keeping up with the real-time requirements and movements of 2+
                million customers is a strategic imperative.
              </p>

              <div className="casestudy-container-right">
                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-20 quote-line-height">
                    <i>
                      “Lenses.io is a great product that helps companies boost
                      natural Kafka-based streaming capabilities with DataOps
                      best practices.”
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>
                      Boris Lentini, Account Site & Delivery Director -{' '}
                      <a
                        href="//www.griddynamics.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Grid Dynamics
                      </a>
                    </i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-dark text-uppercase h4-like">
                <b>Goals</b>
              </p>

              <h2 className="h3-like mb-1">
                Streaming Data Platform considerations
              </h2>

              <p>
                To apply these principles, Vladi and his team considered the
                following architectural drivers.
              </p>

              <ul className="red-checked-list pl-0">
                <li>
                  <span>
                    Events are processed when they happen (evolution from batch
                    to real-time)
                  </span>
                </li>
                <li>
                  <span>
                    Events are sourced enterprise-wide (one producer and n
                    consumers)
                  </span>
                </li>
                <li>
                  <span>
                    Events are stored durably while maintaining real-time replay
                  </span>
                </li>
                <li>
                  <span>No silos or point-to-point connections</span>
                </li>
                <li>
                  <span>Scalable and resilient platform</span>
                </li>
                <li>
                  <span>
                    Based on an Open Source paradigm (leveraging innovation from
                    the community and built on Interchangeable components)
                  </span>
                </li>
              </ul>

              <h2 className="h3-like mb-1 mobile-margin-top">
                Democratizing data with governance controls
              </h2>

              <p>
                Their technologies of choice, Apache Kafka, Hadoop and Flink,
                offered the flexibility and reliability to harness their
                multitude of data. This architecture evolved into Viseca’s
                Streaming Data Platform (SDP).
              </p>

              <p>
                The team also needed visibility into what was happening in their
                SDP to fulfil their governance goals, giving both technical and
                non-technical users a way to operate the data, under
                authentication or authorization control.
              </p>

              <p>
                Lenses.io supports Viseca’s SDP with the following features,
                allowing teams to build and operate real-time data flows without
                the need to learn each individual technology in detail.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="footer-color mobile-margin-top">
          <div className="container-1">
            <div className="d-flex flex-wrap py-3">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom-half">
                <div className="f-16 py-3 mobile-text-center">
                  <img
                    className="w-25 py-2"
                    src="//images.ctfassets.net/tnuaj0t7r912/7F1oXeb71Rv5dfG9zmCiAl/c32003e4e287526ef6e09cadebdb42f2/Monitoring_Alerting.svg"
                    alt="Monitoring & Alerting"
                  />
                  <br />
                  <p className="p-text">
                    <b>Monitoring & alerting </b>
                    on the performance of flows and underlying infrastructure.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom-half">
                <div className="f-16 py-3 mobile-text-center">
                  <span>
                    <img
                      className="w-25 py-2"
                      src="//images.ctfassets.net/tnuaj0t7r912/2eMC8qIGhqq5s169BBbs3V/e8d80b0ba0e939f54ee3f9aacb5f9603/SQLProcessors.svg"
                      alt="SQL processors"
                    />
                  </span>
                  <br />
                  <p className="p-text">
                    <b>SQL Processors </b>
                    to build and run streaming data pipelines defined with SQL
                    syntax over Flink.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom-half">
                <div className="f-16 py-3 mobile-text-center">
                  <span>
                    <img
                      className="w-25 py-2"
                      src="//images.ctfassets.net/tnuaj0t7r912/6iX77yosh8uVFvwGVypOfU/4126925c037ae647de6aab936c834c8e/Administration.svg"
                      alt="Administration"
                    />
                  </span>
                  <br />
                  <p className="p-text">
                    <b>Administration </b>
                    via Lenses to manage schemas with role-based access controls
                    and auditing.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mobile-margin-bottom-half">
                <div className="f-16 py-3 mobile-text-center">
                  <img
                    className="w-25 py-2"
                    src="//images.ctfassets.net/tnuaj0t7r912/uF1aflJyPOggahcNqEiYi/5f26876b3270a4c2450834dc76f8923f/Topology.svg"
                    alt="Topology view"
                  />
                  <br />
                  <p className="p-text">
                    <b>Topology view </b>
                    provides visibility into data provenance and pipeline
                    lineage.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <p className="h4-like mt-3">RESULTS & LESSONS LEARNED</p>

              <h2 className="h3-like-dark mb-1 text-dark pb-0">
                From Proof of Concept to production in one year
              </h2>
              <ModalImage
                className="w-90 pt-3 mobile-margin-bottom mb-3"
                small={streaming_platform_adoption}
                large={streaming_platform_adoption}
                alt="Streaming platform adoption"
              />

              <p>
                The secret to success for Viseca’s Streaming Data Platform
                wasn’t only in identifying the right technologies. They achieved
                both short-term business results and paved the way for
                longer-term customer-centric innovation through creating shared
                data experiences across the organization.
              </p>

              <p>
                Vladi and his team have made great progress in turning a complex
                siloed project into an enterprise-wide Streaming Data Platform
                that delivers measurable business results.
              </p>

              <p>
                It no longer takes weeks to integrate with data on-demand; it
                takes fewer than 10 minutes, with &lt;1 second latency.
              </p>

              <p>
                It continues to transform the introduction of a Streaming Data
                Platform from a complex siloed project, into an enterprise-wide
                imperative.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <section className="bg-cyan mobile-margin-top mt-5">
          <div className="container-1 py-5">
            <div className="d-flex flex-wrap justify-content-around">
              <div className="col-sm col-12 px-4 text-center">
                <h2 className="f-32 fw-900">
                  &lt;1 second <br /> latency
                </h2>
                <p>to exchange data</p>
              </div>
              <div className="col-sm col-12 px-4 text-center mobile-margin-top">
                <h2 className="f-32 fw-900">
                  20x faster data <br /> synchronization
                </h2>
                <p>between apps</p>
              </div>
              <div className="col-sm col-12 px-4 text-center mobile-margin-top">
                <h2 className="f-32 fw-900">
                  Minutes to build & <br /> deploy
                </h2>
                <p>simple stream processing apps</p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-5">
          <div className="d-flex flex-wrap justify-content-center ">
            <div className="col-sm-9 col-12">
              <h2 className="h3-like-dark mb-0 mt-3 text-dark pb-0">
                Outcomes
              </h2>
              <p className="text-dark mb-0 mt-2 mb-2 mobile-margin-top">
                <i>
                  <b>For Marketing </b>
                </i>
              </p>
              <p className="p-text">
                The introduction of real-time streaming services meant that
                teams could innovate without constraints, speeding up
                time-to-market of streaming apps by 10x and sending 600k
                targeted communications to customers.
              </p>
              <p className="text-dark mb-0 mt-2 mb-2 mobile-margin-top">
                <i>
                  <b>For IT</b>
                </i>
              </p>
              <p className="p-text">
                The fast & easy development processing of data pipelines through
                the Lenses.io toolset meant high adoption rates without the need
                to build deep internal expertise for every open-source
                technology - such as Apache Kafka.
              </p>
              <p className="text-dark mb-0 mt-2 mb-2 mobile-margin-top">
                <i>
                  <b>Security & governance</b>
                </i>
              </p>
              <p className="p-text">
                Viseca adheres to strict data governance and compliance
                standards, through the following Lenses.io features:
              </p>
              <ul className="red-checked-list pl-0">
                <li>
                  <span className="pl-1 p-text">
                    Enterprise security and access control policies
                  </span>
                </li>
                <li>
                  <span className="pl-1 p-text">
                    Topic schema and centralized management
                  </span>
                </li>
                <li>
                  <span className="pl-1 p-text">
                    Centralized SQL-enabled data access rules
                  </span>
                </li>
              </ul>
              <p className="text-dark mb-0 mt-2 mb-2 mobile-margin-top">
                <i>
                  <b>Development </b>
                </i>
              </p>
              <p className="p-text">
                Not only could developers build and deploy simple apps in
                minutes, but Lenses.io offered strong support in terms of
                quality and needed time for bug fixes (data inspection).
              </p>

              <div className="casestudy-container-right">
                <div className="bg-white text-left p-4 position-relative">
                  <p className="f-20 quote-line-height">
                    <i>
                      &ldquo;If our engineers had to log into each server to
                      troubleshoot or run instructions from the command line to
                      inspect data, they would have gone crazy. Lenses.io
                      immediately helped by providing a central solution with an
                      intuitive UI for visibility into the whole data
                      environment.&rdquo;
                    </i>
                  </p>
                  <p className="primary-text fw-600">
                    <i>Vladimiro Borsi, Enterprise IT Architect</i>
                  </p>
                  <div>
                    <div className="casestudy-quotes">
                      <StaticImage
                        src="../../resources/images/vectors/quotes.svg"
                        placeholder="tracedSVG"
                        alt="quotes"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-dark mb-0 mt-2 mb-2 mobile-margin-top">
                <i>
                  <b>Operations </b>
                </i>
              </p>
              <p className="p-text">
                Operations benefited from the information flow of dynamic key
                performance indicators, integrated alerting to service operators
                and strong support to incident troubleshooting (data search).
              </p>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Get your DataOps workspace for Apache Kafka:'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default viseca
